import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/database'; // Firebase database
import 'firebase/storage';  // Firebase storage

// const config = {
//   apiKey: "AIzaSyCBev7zvmw5I4JYHwiEgoK_pOfhpgk5V34",
//   authDomain: "kindredprint.firebaseapp.com",
//   databaseURL: "https://kindredprint.firebaseio.com",
//   projectId: "kindredprint",
//   storageBucket: "kindredprint.appspot.com",
//   messagingSenderId: "411891704874",
//   appId: "1:411891704874:web:6970b11e616e3e071c0f53"
// };

const config = {
  apiKey: "AIzaSyC8F-kz2HkFfbdCsrA2rM5lgVlj3hDScGE",
  authDomain: "nurseryprinting.firebaseapp.com",
  databaseURL: "https://nurseryprinting.firebaseio.com",
  projectId: "nurseryprinting",
  storageBucket: "nurseryprinting.appspot.com",
  messagingSenderId: "33068550989",
  appId: "1:33068550989:web:8782a6a4fb18019bbdc504"
};

// const config = {
//   apiKey: 'AIzaSyDSYR1Wy-S42JIJZTbTbxwhCFaiNzaFtac',
//   authDomain: 'blacksquareportal.firebaseapp.com',
//   databaseURL: 'https://blacksquareportal.firebaseio.com',
//   projectId: 'blacksquareportal',
//   storageBucket: 'blacksquareportal.appspot.com',
//   messagingSenderId: '317737964824',
//   appId: '1:317737964824:web:581ec03fb2c7370dcdba6d'
// };

firebase.initializeApp(config);

// this is created to stop the user being signed in automatically to
// the newly created user - otherwise the admin would be immediately
// logged in as the user they just created
const firebaseAuth = firebase.initializeApp(config, 'auth');

const database = firebase.database();
// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// firebase storage
const storage = firebase.storage();

export { firebase, firebaseAuth, storage, database as default };
