import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const UserProductListItem = ({ userid, id, title, image, productid }) => {
    return (

        <div className="user-list-item">
            <div className="user-info">
                <div className="user-info-image">
                <div className="user-info-image-inner" style={image ? { backgroundImage: `url(${image})` } : { backgroundImage: `url("/images/placeholder-img.jpg")`}}></div>
                </div>
                <div className="user-info-name">{productid} -&nbsp;</div>
                <div className="user-info-name">{title}</div>
            </div>
            <div>
                <Link to={`/edit-product/${userid}/product/${id}`} className="link-button button--margin-left">
                    <Button variant="contained" color="primary" className="button button-primary-edit">
                        Edit Product
                    </Button>
                </Link>
            </div>
        </div>
    );

};

export default UserProductListItem;