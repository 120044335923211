import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { firebase } from '../firebase/firebase';
import { startSetCurrentUser } from '../actions/auth';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const LoginPage = ({ startSetCurrentUser, history, auth }) => {
  const [loginValues, setLoginValues] = useState({
    email: '',
    password: '',
    showPassword: false,
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (auth.currentUser && auth.currentUser.role === 'admin') {
      history.push('/admindashboard');
    } else if (auth.currentUser) {
      history.push('/usersdashboard');
    }
  }, [auth.currentUser]);

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    console.log('login submit');

    firebase
      .auth()
      .signInWithEmailAndPassword(loginValues.email, loginValues.password)
      .then((userCredential) => {
        // User is logged in successfully.
        const user = userCredential.user;

        //console.log('login user', user.uid);
  
        // Fetch user data from the database
       // const userRef = firebase.database().ref(`users/${user.uid}`);
  
        // userRef.once('value')
        //   .then((snapshot) => {
        //     const userData = snapshot.val();
        //     // You can now access user data and update your app's state accordingly.
        //   })
        //   .catch((error) => {
        //     setError('Error fetching user data.');
        //   });
      })
      .catch((e) => {
        setError('The username or password you entered was incorrect.');
      });
  };

  const handleInputChange = (e, key) => {
    const newVals = { ...loginValues };
    newVals[key] = e.target.value;
    setLoginValues(newVals);
  };

  const handleClickShowPassword = () => {
    setLoginValues({ ...loginValues, showPassword: !loginValues.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className="login-page">
      <form onSubmit={handleLoginSubmit} className="login-form">
        <img className="login-logo" src="/images/logo-v2.png" alt="Nursery Printing Portal" />
        <TextField
          fullWidth
          type="email"
          name="email-address"
          id="email-address"
          label="Email Address"
          className="textField"
          value={loginValues.email}
          onChange={(e) => handleInputChange(e, 'email')}
        />
        <FormControl className="textField">
          <InputLabel htmlFor="adornment-password">Password</InputLabel>
          <Input
            id="adornment-password"
            type={loginValues.showPassword ? 'text' : 'password'}
            value={loginValues.password}
            onChange={(e) => handleInputChange(e, 'password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {loginValues.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {error && <div>{error}</div>}
        <div className="MuiButton-fullWidth m-t-20">
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            className="button"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth
});

const mapDispatchToProps = (dispatch) => ({
  startSetCurrentUser: (userId) => dispatch(startSetCurrentUser(userId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPage));
